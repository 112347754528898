import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
  state: {
    blogList: [],
    categoryList: [],
    programList: [],
    electiveList:[],
  },
  getters: {
    blogs: (state) => state.blogList,
  },
  mutations: {
    updateBlogList: (state, data) => {
      state.blogList = data
    },
    categoryList: (state, data) => {
      state.categoryList = data
    },
    programList: (state, data) => {
      state.programList = data
    },
    electiveLists: (state, data)=>{
      state.electiveList = data
    }
  },
  actions: {
    async fetchBlogPosts({ commit }) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}/blog/blog-list/?size=4`,
        )
        console.log('bloogggggggg', response.data.data)
        commit('updateBlogList', response.data.data)
      } catch (error) {
        console.error('Error fetching blog posts:', error)
      }
    },
    // courseCategoryCall
    async courseCategoryCall({ commit }) {
      try {
        const resp = await axios.get(
          `${process.env.VUE_APP_API}/course/category-list/`,
        )
        commit('categoryList', resp.data.data)
      } catch (error) {
        console.error('Error fetching category list', error)
      }
    },
    // courseProgramCall
    async courseProgramCall({ commit }) {
      try {
        const resp = await axios.get(
          `${process.env.VUE_APP_API}/course/program-list/`,
        )
        commit('programList', resp.data.data)
      } catch (error) {
        console.error('Error fetching category list', error)
      }
    },
    // electiveCall
    async electiveCall({ commit }) {
      try {
        const resp = await axios.get(
          `${process.env.VUE_APP_API}/course/course/elective-list/`,
        )
        commit('electiveList', resp.data.data)
      } catch (error) {
        console.error('Error fetching elective list', error)
      }
    },
  },
  modules: {},
})
