import { createApp as o } from "vue";
import e from "./App.vue";
import "./registerServiceWorker";
import r from "./router";
import m from "./store";
import "./style.css";
import "./common.css";

import "slick-carousel/slick/slick.css";
import "jquery";

import { createHead as s } from "@unhead/vue";

let app = o(e);
app.component();
let head = s();
app.use(head);
app.use(m).use(r).mount("#app");
