// generalRedirections.js
export const generalRedirectionMap = {
  "/programs/masters/online-master-of-business-administration-degree": "/master-business-administration-online-degree",
  "/programs/masters/mba-strategic-human-resource-management-degree-online": "/online-mba/strategic-finance",
  "/brochure/mba/blogs/deciding-between-bba-and-bcom-which-is-the-ideal-choice-post-12th": "/blogs",
  "/brochure/mba/blogs/a-guide-to-data-analytics-and-simpy-for-business-optimization": "/blogs",
  "/brochure/mba/blogs/real-world-applications-of-bba-marketing-concepts": "/blogs",
  "/programs/masters/ma-degree-online": "/master-of-arts-online-degree",
  "/brochure/mca/blogs/beyond-python-and-r-exploring-julia-for-high-performance-data-workflows": "/blogs",
  "/brochure/mba/blogs/beyond-python-and-r-exploring-julia-for-high-performance-data-workflows": "/blogs",
  "/sites/default/files/2022-02/mca_game_development.pdf": "/masters-computer-application-online-degree",
  "/index.php/mba-finance-degree-online": "/online-mba/finance",
  "/index.php/mca-data-analytics-degree-online": "/online-mca/data-analytics",
  "/programs/master/mba-retail-banking-operations-degree-online": "/online-mba/retail-banking-operations",
  "/master-business-administration-online-degree/blogs/career-opportunities-in-accounting-and-finance-the-role-of-mcom-program": "/blogs",
  "/index.php/ma-public-policy-and-administration-degree-online": "/online-ma/public-policy",
  "/index.php/mba-human-resource-management-degree-online": "/online-mba/human-resources-management",
  "/online-mca/bachelor-of-commerce-online-degree": "/masters-computer-application-online-degree",
  "/brochure/ma/blogs/top-5-case-studies-every-mba-project-management-student-should-explore": "/blogs",
  "/index.php/bba-digital-marketing-degree-online": "/online-bba/digital-marketing",
  "/brochure/ma/blogs/celebrating-mothers-who-juggle-it-all-happy-mothers-day": "/blogs",
  "/programs/master/mba-digital-finance-degree-online": "/online-mba/finance",
  "/master-business-administration-online-degree/blogs/social-media-marketing-tips-from-mba-marketing-graduates": "/blogs",
  "/index.php/bcom-professional-accounting-and-finance-degree-online": "/online-bcom/accounting-and-finance",
  "/sites/default/files/2023-07/Theory-Examination-Time-Table-For-Reports.pdf": "/",
  "/brochure/ma/blogs/social-media-marketing-tips-from-mba-marketing-graduates": "/blogs",
  "/master-business-administration-online-degree/blogs/celebrating-mothers-who-juggle-it-all-happy-mothers-day": "/blogs",
  "/brochure/ma/blogs/career-opportunities-in-accounting-and-finance-the-role-of-mcom-program": "/blogs",
  "/blog/explore-significance-bcom-international-finance-and-accounting-program": "/blogs",
  "/blog/mastering-full-stack-development-key-skills-and-learning-pathways": "/blogs",
  "/online-mba/blog/beyond-boundaries-global-opportunities-online-mca-education": "/blogs",
  "/brochure/ma/blog/beyond-boundaries-global-opportunities-online-mca-education": "/master-of-arts-online-degree",
  "/blog/10-reasons-pursue-online-certification-programs-entrepreneurship-development": "/blogs",
  "/programs/masters/mca-cyber-security-collaboration-with-TCS-degree-online": "/online-mca/cyber-security",
  "/programs/master/mba-lean-operations-management-degree-online": "/master-business-administration-online-degree",
  "/programs/masters/mca-cyber-security-degree-online": "/online-mca/cyber-security",
  "/brochure/mcom/blog/explore-significance-bcom-international-finance-and-accounting-program": "/blogs",
  "/programs/bachelors/bba-banking-and-finance-degree-online": "/online-bba/finance",
  "/genetics-certification-online": "/micro-credentials-online-courses",
  "/blog/why-corporate-accounting-is-a-good-career-option": "/blogs",
  "/blog/role-strategic-human-resource-management-organizational-success": "/blogs",
  "/master-business-administration-online-degree/blog/beyond-boundaries-global-opportunities-online-mca-education": "/blogs",
  "/brochure/ma/blogs/explore-significance-bcom-international-finance-and-accounting-program": "/blogs",
  "/master-business-administration-online-degree/blogs/explore-significance-bcom-international-finance-and-accounting-program": "/blogs",
  "/blog/explore-significance-bcom-international-finance-and-accounting-program/": "/blogs",
  "/brochure/bba/blogs/explore-significance-bcom-international-finance-and-accounting-program": "/blogs",
  "/brochure/ma/blogs/top-10-job-opportunities-after-mba-healthcare-management": "/blogs",
  "/blog/beyond-boundaries-global-opportunities-online-mca-education": "/blogs",
  "/blogs/evolving-landscape-healthcare-management-education": "/blogs",
  "/blog/top-10-final-year-mba-marketing-projects-boost-your-career": "/blogs",
  "/programs/masters/mba-business-and-artificial-intelligence-degree-online": "/master-business-administration-online-degree",
  "/mba-marketing-and-human-resource-management-degree-online": "/online-mba/hr-marketing",
  "/blogs/how-online-mca-data-science-programs-accelerate-career-growth": "/blogs",
  "/blog/top-10-job-opportunities-after-mba-healthcare-management/": "/blogs",
  "/sites/default/files/2024-03/DEB-Public-Notice-Precautions-to-be-taken-by-students.pdf": "/",
  "/blog/top-10-job-opportunities-after-mba-healthcare-management": "/blogs",
  "/blogs/how-online-degree-program-going-survive-longer-run": "/blogs",
  "/index.php/cancellation-and-refund-rules": "/refund-policy",
  "/index.php/programs/certifications":  "/all-programs",
  "/sites/default/files/2021-12/Aviation%20Management.pdf": "/bachelor-of-business-administration-online-degree",
  "/sites/default/files/2021-12/Aviation Management.pdf": "/bachelor-of-business-administration-online-degree",
  "/sites/default/files/2021-12/International%20Marketing.pdf": "/sites/default/files/2022-11/MBA-Marketing.pdf",
  "/sites/default/files/2021-12/Aviation%20Management_0.pdf": "/bachelor-of-business-administration-online-degree",
  "/sites/default/files/2021-12/Aviation Management_0.pdf": "/bachelor-of-business-administration-online-degree",
  "/sites/default/files/2021-12/International%20Finance_0.pdf": "",
  "/sites/default/files/2021-12/International Finance_0.pdf": "",
  "/sites/default/files/2021-12/Investment%20Banking%20_%20Equity%20Research.pdf": "",
  "/sites/default/files/2021-12/Investment Banking _ Equity Research.pdf": "",
  "/sites/default/files/2021-12/Marketing%20and%20Human%20Resource%20Management.pdf": "/sites/default/files/2022-11/MBA-Human-Resource-Management.pdf",
  "/sites/default/files/2021-12/Strategy%20_%20Leadership.pdf": "",
  "/sites/default/files/2021-12/Strategy _ Leadership.pdf": "",
  "/sites/default/files/2021-12/Corporate%20Secretaryship.pdf": "/",
  "/sites/default/files/2021-12/Corporate Secretaryship.pdf": "/",
  "/sites/default/files/2021-12/Corporate%20Accounting.pdf": "/",
  "/sites/default/files/2021-12/Corporate Accounting.pdf": "/",
  "/sites/default/files/2021-12/General%20Management%20.pdf": "/sites/default/files/2022-11/MBA-General-Management.pdf",
  "/sites/default/files/2021-12/Finance%20and%20Leadership_0.pdf": "/master-business-administration-online-degree",
  "/sites/default/files/2021-12/Finance and Leadership_0.pdf": "/master-business-administration-online-degree",
  "/sites/default/files/2021-12/Financial%20Markets_0.pdf": "/master-business-administration-online-degree",
  "/sites/default/files/2021-12/Financial Markets_0.pdf": "/master-business-administration-online-degree",
  "/sites/default/files/2021-12/International%20Finance%20_%20Accounting.pdf": "/master-business-administration-online-degree",
  "/sites/default/files/2021-12/International Finance _ Accounting.pdf": "/master-business-administration-online-degree",
  "/index.php/mba-banking-and-finance- degree-online": "/online-mba/banking-and-finance",
  "/index.php/mba-banking-and-finance-%20degree-online": "/online-mba/banking-and-finance",
  "/index.php/mba-logistics-and-supply-chain-management- degree-online": "/online-mba/logistic-and-supply-chain-management",
  "/index.php/mba-logistics-and-supply-chain-management-%20degree-online": "/online-mba/logistic-and-supply-chain-management",
  "/index.php/mcom-international-finance-degree online": "/online-mcom/international-finance",
  "/index.php/mcom-international-finance-degree%20online": "/online-mcom/international-finance",
  "/sites/default/files/2021-12/Cloud%20Computing.pdf": "/sites/default/files/2022-11/MCA-Cloud-Computing.pdf",
};
export function getRedirectPath(currentPath) {
  return generalRedirectionMap[currentPath] || null;
}