<template>
  <router-view />
</template>
<style lang="scss">
 #app {
   text-align: center;
   color: #2c3e50;
 }
nav {
  a {
    font-weight: bold;
    color: #2c3e50;
  }
}
</style>
